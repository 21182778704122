import { useEffect } from "preact/hooks";

interface AccessTokenHandlerProps {
  setIsAuthenticated: (authStatus: boolean) => void;
}

export const AccessTokenHandler = ({ setIsAuthenticated }: AccessTokenHandlerProps) => {
  // Function to fetch the access token
  const fetchAccessToken = async () => {
    try {
      const response = await fetch("/api/oauth_check-tokens", {
        method: "GET",
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setIsAuthenticated(!!data.accessToken);  // Set authentication status based on access token
      } else {
        setIsAuthenticated(false);  // No authentication if response is not OK
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
      setIsAuthenticated(false);  // No authentication on error
    }
  };

  // Fetch access token when the component is first rendered
  useEffect(() => {
    fetchAccessToken();
  }, []);

  return null; // This component doesn't render anything, only handles token fetching
};
